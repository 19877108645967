<template>
    <div class="header split-two" ref="header">
        <div class="split-two">
            <button class="section-icon" @click="setShowMobileMenu(true)" />
            <router-link to="/home" class="logo-container">
                <div class="logo-word">磊山</div>
                <div class="logo-grep" />
                <div class="logo-word" v-text="clientName" />
            </router-link>
        </div>
        <div class="split-two">
            <div id="filter-icon-teleport" />
            <div class="profile-container to-right">
                <template v-if="$isLogin()">
                    <div class="user-name">
                        {{ $store.state.userInfo.name }}
                    </div>
                    <div class="login-logout" @click="$logout()">登出</div>
                    <div
                        class="btn-wrapper"
                        :class="{ focus: isShowLicenseInfo }"
                        @click="modalHandler('licenseInfo')"
                    >
                        <div class="license-info-btn" />
                    </div>
                    <div
                        class="btn-wrapper"
                        :class="{ focus: isShowQuickLink }"
                        @click="modalHandler('quickLink')"
                    >
                        <div class="quick-link-btn" />
                    </div>
                </template>
                <template v-if="!$isLogin()">
                    <div class="login-logout" @click="$login()">登入</div>
                </template>
            </div>
        </div>
    </div>
    <div v-if="lazyLoad.licenseInfo">
        <LicenseInfoModal v-model="isShowLicenseInfo" class="dashboard-modal" />
    </div>
    <div v-if="lazyLoad.quickLink">
        <QuickLinkModal v-model="isShowQuickLink" class="dashboard-modal" />
    </div>
</template>

<script>
import { lazyLoadComponent } from '@/assets/javascripts/util'
const LicenseInfoModal = lazyLoadComponent('containers/LicenseInfoModal')
const QuickLinkModal = lazyLoadComponent('containers/QuickLinkModal')

export default {
    name: 'Header',
    components: {
        LicenseInfoModal,
        QuickLinkModal
    },
    methods: {
        setShowMobileMenu: function (isShow) {
            this.$store.commit('set', {
                showMobileMenu: isShow
            })
        },
        turnOnFilterModal: function () {
            this.$store.commit('set', {
                isShowFilter: true
            })
        },
        detectMenuHeight: function () {
            const header = this.$refs.header
            let scrollHeight = header.scrollHeight
            this.$store.commit('set', { webHeaderHeight: scrollHeight })
        },
        modalHandler: function (modal = '') {
            this.lazyLoad[modal] = true
            if (modal === 'licenseInfo') {
                this.$store.commit('set', {
                    isShowLicenseInfo: true
                })
            }
            if (modal === 'quickLink') {
                this.$store.commit('set', {
                    isShowQuickLink: true
                })
            }
        }
    },
    computed: {
        isShowLicenseInfo: {
            get() {
                return this.$store.state.isShowLicenseInfo
            },
            set(val) {
                this.$store.commit('set', {
                    isShowLicenseInfo: val
                })
            }
        },
        isShowQuickLink: {
            get() {
                return this.$store.state.isShowQuickLink
            },
            set(val) {
                this.$store.commit('set', {
                    isShowQuickLink: val
                })
            }
        }
    },
    watch: {
        '$route.path': {
            handler() {
                this.setShowMobileMenu(false)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            clientName: '業務管理系統',
            store: this.$store,
            lazyLoad: {
                licenseInfo: false,
                quickLink: false
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            const header = this.$refs.header
            let domDetector = new ResizeObserver(this.detectMenuHeight)
            domDetector.observe(header)
            if (
                (this.$isLogin() &&
                    this.$store.state.dashboardLicense &&
                    Object.keys(this.$store.state?.dashboardLicense).length ===
                        0 &&
                    !this.$store.state.lastUpdateHeaderApiTime) ||
                this.$shouldUpdateHeaderApi()
            )
                this.$store.commit('set', {
                    lastUpdateHeaderApiTime: new Date()
                })
            this.lazyLoad.licenseInfo = true
            this.lazyLoad.quickLink = true
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/icon.scss';
.header {
    height: $header-height;
    width: 100vw;
    background-color: $primary-white;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    position: fixed;
    z-index: 8;

    @media screen and (min-width: 1281px) {
        padding: 15px calc((100vw - #{$primary-central-width}) / 2);
    }
    @media screen and (min-width: 0px) and (max-width: 1280px) {
        padding: 15px Max(15px, env(safe-area-inset-left, 15px));
        height: $mobile-header-height;
    }
}

.split-two {
    align-items: center;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-word {
    font-family: $secondary-cn;
    color: $primary-red;
    line-height: 37px;
    letter-spacing: normal;
    font-weight: 700;
    font-size: 1.5rem;
    font-weight: bold;

    @media screen and (min-width: 0px) and (max-width: 1280px) {
        font-size: 1.3rem;
    }
}

.logo-grep {
    width: 3px;
    height: 1.5rem;
    background-color: $primary-red;
    margin: 0 10px;

    @media screen and (min-width: 0px) and (max-width: 575px) {
        height: 1.3rem;
    }
}

.section-icon {
    height: 22px;
    width: 22px;
    margin-right: 20px;

    @media screen and (min-width: 1281px) {
        display: none;
    }

    @media screen and (min-width: 1281px) and (max-width: 1280px) {
        display: none;
    }
}

.profile-container {
    & div {
        display: flex;
        align-items: center;
    }

    & > div {
        display: flex;
        font-size: 14px;
    }

    .user-name {
        font-size: 14px;
        margin-right: 10px;
    }

    .login-logout {
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;
    }

    .btn-wrapper {
        margin-left: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        > div {
            margin-left: auto;
            margin-right: auto;
        }
        .license-info-btn {
            @include license_info($sixth-black);
            background-size: 24px 20px;
            width: 24px;
            height: 20px;
        }
        .quick-link-btn {
            @include candy_box($sixth-black);
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
        }
        &.focus {
            border-radius: 50%;
            background: $dashboard-gradient;
            .license-info-btn {
                @include license_info($primary-white);
            }

            .quick-link-btn {
                @include candy_box($primary-white);
            }
        }
    }

    // @media screen and (min-width: 768px) and (max-width: 1280px) {
    //     display: none;
    // }

    // @media screen and (min-width: 576px) and (max-width: 767px) {
    //     display: none;
    // }

    @media screen and (min-width: 1018px) and (max-width: 1280px) {
        margin-right: 15px;
    }
    @media screen and (min-width: 0px) and (max-width: 576px) {
        display: none;
    }
}

//dashboard
.dashboard-modal.modal {
    @media screen and (min-width: 1281px) {
        top: 85px;
        right: calc((100vw - 1260px) / 2);
        left: auto;
    }

    @media (min-width: 577px) and (max-width: 1280px) {
        top: 60px;
        right: 15px;
        left: auto;
    }
    @media screen and (max-width: 576px) {
        top: 0;
        right: 0;
        left: 0;
    }
}
</style>
